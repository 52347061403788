import { template as template_f3ca2e1460504e11a05cb293b552e961 } from "@ember/template-compiler";
const FKText = template_f3ca2e1460504e11a05cb293b552e961(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
