import { template as template_eb33e367c3bb4d5c9ce5f1cc906799b2 } from "@ember/template-compiler";
import DButton from "discourse/components/d-button";
const BackButton = template_eb33e367c3bb4d5c9ce5f1cc906799b2(`
  <DButton
    @action={{@onGoBack}}
    @label="topic.timeline.back"
    @title="topic.timeline.back_description"
    class="btn-primary btn-small back-button"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default BackButton;
