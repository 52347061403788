import { template as template_892cfa61121f4d908c6f6d2eaedbf12a } from "@ember/template-compiler";
const WelcomeHeader = template_892cfa61121f4d908c6f6d2eaedbf12a(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
